@import '~antd/dist/antd.css';

html {
  scroll-behavior: smooth;
}

.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.AppHeader {
  background: linear-gradient(90deg, rgb(2,0,36) 0%, rgb(9,9,121) 35%, rgb(75,0,75) 100%);
  padding-left:5vw;
  padding-right:5vw;
}

.LoginSignup {
  text-align: right;
}

.AppLogo {
  height: auto;
  width: 100%;
  max-width: 120px;
}

.SearchBar {
  width: 30vw;
}

.ContentContainer {
  margin-top: 64px; 
  background-color: rgb(245,245,245);
  min-height: calc(100vh - 64px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ListBackground {
  background-color: rgb(245,245,245);
  min-height: 200;
  width:100%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
}

.player-wrapper > div {
  position: absolute;
  top: 0;
  left: 0;
}

.cap {
  display:none;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 12px;
  top: 76px;
}

.stickyCol {
  display:flex;
  padding-bottom:24px;
}

.stickyContent {
  width:20vw;
  max-width:400px;
  min-width:200px;
  height:425px;
}

ul.ant-comment-action {
  align-content: flex-start;
}
/*div.ant-modal-content {
  height:0;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(75,0,75,1) 100%);
}*/
li.ant-list-item {
  padding-top: 7px;
  padding-bottom: 7px;
}
div.ant-modal-header {
  background-color:white;
  /*background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(75,0,75,1) 100%);*/
}
div.ant-card.ant-card-bordered {
  box-shadow: 0px 0px 4px 4px rgb(238,238,238);
}
div.ant-tooltip {
  z-index: 4;
}

iframe {
  width:100%;
}

.Footer {
  text-align: center;
  padding: 10px;
  border-top: 1px solid lightgray;
  /*background-color: rgba(245,245,245,255);*/
  background-color: rgb(220, 224, 233);
}